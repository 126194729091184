import React from "react";

const Etc = () => {
  return (
    <div>
      <p>DO COMPUTERS</p>
      <p>COMPUTERS GOOD</p>
    </div>
  );
};

export default Etc;
